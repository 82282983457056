import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { FileUploader } from "react-drag-drop-files";
import { CircularProgress, Button, Container, Typography, Paper } from '@mui/material';

const API_BASE_URL = 'https://api.ultraparse.com';

function App() {
  const [file, setFile] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [jobStatus, setJobStatus] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [error, setError] = useState(null);

  const handleChange = (file) => {
    setFile(file);
    setError(null);
  };

  const handleSubmit = async () => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${API_BASE_URL}/ultraparse`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setJobId(response.data.job_id);
      setJobStatus('Queued');
    } catch (err) {
      setError('Failed to start the UltraParse job. Please try again.');
    }
  };

  useEffect(() => {
    let interval;
    if (jobId && jobStatus !== 'Completed' && jobStatus !== 'Failed') {
      interval = setInterval(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/job/${jobId}`);
          setJobStatus(response.data.status);
          if (response.data.status === 'Completed') {
            setMarkdownContent(response.data.markdown_raw);
          } else if (response.data.status === 'Failed') {
            setError('Job failed. Please try again.');
          }
        } catch (err) {
          setError('Failed to fetch job status. Please try again.');
          clearInterval(interval);
        }
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [jobId, jobStatus]);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        UltraParse Engine by Localmind (DEMO)
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <FileUploader handleChange={handleChange} name="file" types={['PDF', 'DOCX', 'PPTX', 'XLSX']} />
        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '10px' }}>
          Uploaden und loslegen
        </Button>
        {error && (
          <Typography color="error" style={{ marginTop: '10px' }}>
            {error}
          </Typography>
        )}
        {jobStatus && jobStatus !== 'Completed' && (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <CircularProgress />
            <Typography>{jobStatus}...</Typography>
          </div>
        )}
      </Paper>
      {markdownContent && (
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Extrahiertes Material
          </Typography>
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </Paper>
      )}
    </Container>
  );
}

export default App;
